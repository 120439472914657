.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.box-item {
  text-align: center;
  // padding-top: 34px;
  font-size: 16px;
  height: 100%;
}

.image-user{
  width: 75px;
  height: 75px;
  border-radius: 100%;
}

.annotation {
  font-size: 12px;
}

.border {
  width: 160px;
  height: 50px;
  stroke: rgba(191, 191, 191, 0.25);
  stroke-width: 1px;
  fill: transparent;
}

.state {
  font-weight: 500;
  font-size: 14px;
}

.caption {
  font-weight: 500;
}

.sup {
  font-size: 0.8em;
}

.dx-toolbar .dx-toolbar-before .dx-item-content div {
  margin-top: 0px !important;
}
