.tabla-user{
    overflow: hidden;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    border-radius: 4px;
    background-color: #da5716e2;
    margin: 10px 0px 0px;
  }

.estatus{
  margin: 0;
  padding: 0;
}

.list-modules{
  background-color: rgb(235, 235, 235);
}

.check .dx-field-item-content.dx-field-item-content-location-bottom{
  display: flex;
  justify-content: center;
}

.dx-toolbar-label > div {
  padding-top: 3vh !important;
}