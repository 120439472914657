.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.btn-agregar{
  background-color: rgb(242, 144, 23) !important;
  background-color: rgb(243, 160, 59) !important;
  color: white !important;
  width: 10vw;
  font-size: 2vh !important;
}

.tabla-Cliente{
  overflow: hidden;
  -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
  box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
  border-radius: 4px;
  background-color: #f49f3c;;
  margin: 10px 0px 0px;
}

.inputBuscador{
  margin-left: 70%;
  width: 300px;
  height: 30px;
  border-radius: 5px;
  border-color: rgb(255, 254, 254);
  text-align: center;
  color: gray;
  margin-top: 40px;
  background-image: url('../../../public/imageness/Search.svg'),
        linear-gradient(to bottom, #f9f9f9 0%, #f7f7f7 100%) !important;
        background-repeat: no-repeat, repeat !important;
        background-position: left 10px top 50%, 0 0 !important;
        background-size: 20px auto, 100% !important;
}

.titleCliente{
  color: rgb(223, 148, 27);
  font-weight: bold !important;
}