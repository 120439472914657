#dropzone-external {
  width: 750px;
  height: 100px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.9;
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box-photo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-image{
  // width: 20%;
  // height: 50%;
  width: 18%;
  margin-top: 3%;
  margin-left: 1.5%;
}

#dropzone-image{
  width: 100%;
  // height: auto;
  height: 70%;
  // margin-top: 15px;
}

svg.delete-img{
  float: right;
  font-size: 25px;
  color: orangered;
  cursor: pointer;
}

.dx-fileuploader-show-file-list .dx-fileuploader-files-container{
  display: none;
}

.flex-box-img{
  flex-flow: row wrap;
}

.dx-card-img{
  overflow: hidden;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border-radius: 4px;
  background-color: #fff;
}